@tailwind base;
@tailwind components;
@tailwind utilities;


*{

        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-style: normal;
   
  scrollbar-width: thin;
    scrollbar-color: #b10d72 #f0f0f0;
/* color: #f167a0; */


   

}


&::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  
}

&::-webkit-scrollbar-thumb {
    background-color: #b10d72;
    border-radius: 5px;
  
}

&::-webkit-scrollbar-track {
    background-color: #f0f0f0;

}