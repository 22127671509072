.slick-prev:before,
.slick-next:before {
  color: rgb(28, 189, 55) !important;
}

.Clientcard{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    margin-bottom: 1rem;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-dots li.slick-active button:before {
  color:orange !important;
}
